import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { SocialIcon } from "react-social-icons";

type Props = {
  title: string;
};

const Share: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation(["share"]);

  const [shareUrl, setShareUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    setShareUrl(window.location.href);
  }, []);

  return shareUrl ? (
    <div className="p-4 bg-gray-100 rounded-xl">
      <div className="flex justify-center">
        <span className="text-center">{t("share:share_this_post")}:</span>
      </div>
      <div className="flex flex-row justify-center pt-2 space-x-2 md:space-x-6">
        <FacebookShareButton
          url={shareUrl}
          title={title}
          className="transform scale-75 border border-black rounded-full md:scale-100"
        >
          <SocialIcon
            network="facebook"
            bgColor="transparent"
            fgColor="black"
          />
        </FacebookShareButton>
        <LinkedinShareButton
          url={shareUrl}
          title={title}
          className="transform scale-75 border border-black rounded-full md:scale-100"
        >
          <SocialIcon
            network="linkedin"
            bgColor="transparent"
            fgColor="black"
          />
        </LinkedinShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          className="transform scale-75 border border-black rounded-full md:scale-100"
        >
          <SocialIcon
            network="whatsapp"
            bgColor="transparent"
            fgColor="black"
          />
        </WhatsappShareButton>
      </div>
    </div>
  ) : null;
};

export default Share;
