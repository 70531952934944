import BlockContent, {
  BlockContentProps,
} from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import ImageWithCaption from "../components/blocks/ImageWithCaption";
import Share from "../components/Share";
import SignupFormCard from "../components/SignupFormCard";
import { useGlobalState } from "../state/globalState";

export interface InAppPostProps {
  data: {
    sanityPost: {
      title: string;
      _rawBody: any;
      _rawAppendix: any;
    };
  };
}

const InAppPost: React.FC<InAppPostProps> = ({ data }) => {
  const { sanityPost: post } = data;

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: true,
    });
  }, []);

  const serializers = React.useMemo<BlockContentProps["serializers"]>(
    () => ({
      types: {
        imageWithCaption: ({ node }) => {
          return <ImageWithCaption {...node} />;
        },
      },
    }),
    []
  );

  return post ? (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="mb-8" />
      <article className="py-16 prose prose-lg container-4xl">
        <h1 className="text-4xl font-bold font-headline">{post.title}</h1>
        <BlockContent
          blocks={post._rawBody}
          projectId={process.env.GATSBY_SANITY_PROJECT_ID}
          dataset={process.env.GATSBY_SANITY_DATASET}
          serializers={serializers}
        />
      </article>
      <div className="flex flex-col mb-4 space-y-8 container-4xl">
        <Share title={post.title} />
        <SignupFormCard />
      </div>
      {post._rawAppendix && (
        <article className="py-16 prose prose-lg container-4xl ">
          <BlockContent
            blocks={post._rawAppendix}
            projectId={process.env.GATSBY_SANITY_PROJECT_ID}
            dataset={process.env.GATSBY_SANITY_DATASET}
            serializers={serializers}
          />
        </article>
      )}
    </>
  ) : null;
};

export default InAppPost;

export const inAppPostQuery = graphql`
  query InAppPostQuery($language: String!, $slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }, i18n_lang: { eq: $language }) {
      id
      title
      slug {
        current
      }
      excerpt
      tags
      _rawBody
      _rawAppendix
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
